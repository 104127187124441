import React from 'react'
import { getLuminance } from 'polished'
import {
  neutral,
  accent,
  paletteDark,
  paletteLight,
  state,
  palette,
  theme,
  themeDark,
} from '@urbaninfrastructure/design-tokens'
import { Flex, Box, Text, List, Li } from '@urbaninfrastructure/react-ui-kit'

const ColorFlex = props => {
  return (
    <Flex
      p="xs"
      mr="xxs"
      mb="xxs"
      width={[1, 1 / 2, 1 / 4]}
      column
      {...props}
    />
  )
}

function FlexWrapper(props) {
  return <Flex flexWrap="wrap" {...props} />
}

export const NeutralColors = () => {
  return (
    <Flex>
      {Object.keys(neutral).map(key => {
        const bg = neutral[key]
        return (
          <ColorFlex
            key={key}
            color={getLuminance(bg) > 0.5 ? 'text' : 'white'}
            bg={bg}
          >
            <Box flex={1} width={1}>
              <div>{key}</div>
              <div>{bg}</div>
            </Box>
          </ColorFlex>
        )
      })}
    </Flex>
  )
}

export const AccentColors = () => {
  return (
    <Text small>
      <Box mb={2}>Accent</Box>
      <Flex>
        {Object.keys(accent).map(key => {
          const bg = accent[key]
          return (
            <ColorFlex
              key={key}
              color={getLuminance(bg) > 0.5 ? 'text' : 'white'}
              bg={bg}
            >
              <Box flex={1} width={1}>
                <div>{key}</div>
                <div>{bg}</div>
              </Box>
            </ColorFlex>
          )
        })}
      </Flex>
    </Text>
  )
}

export const DarkPaletteColors = () => {
  return (
    <FlexWrapper>
      {Object.keys(paletteDark).map(key => {
        const bg = paletteDark[key]
        const color = paletteLight[key]
        return (
          <ColorFlex key={key} color={color} bg={bg}>
            <Box flex={1} width={1}>
              <div>{key}</div>
              <div>{bg}</div>
            </Box>
          </ColorFlex>
        )
      })}
    </FlexWrapper>
  )
}

export const LightPaletteColors = () => {
  return (
    <FlexWrapper>
      {Object.keys(paletteLight).map(key => {
        const bg = paletteLight[key]
        const color = paletteDark[key]
        return (
          <ColorFlex key={key} color={color} bg={bg}>
            <Box flex={1} width={1}>
              <div>{key}</div>
              <div>{bg}</div>
            </Box>
          </ColorFlex>
        )
      })}
    </FlexWrapper>
  )
}

export const PaletteColors = () => {
  return (
    <FlexWrapper>
      {Object.keys(palette).map(key => {
        const bg = palette[key]
        const color = paletteDark[key]
        return (
          <ColorFlex key={key} color={color} bg={bg}>
            <Box flex={1} width={1}>
              <div>{key}</div>
              <div>{bg}</div>
            </Box>
          </ColorFlex>
        )
      })}
    </FlexWrapper>
  )
}

export const StateColors = () => {
  return (
    <Text small>
      <Box mb={2}>State</Box>
      <Flex>
        {Object.keys(state).map(key => {
          const bg = state[key]
          return (
            <ColorFlex
              key={key}
              color={getLuminance(bg) > 0.5 ? 'text' : 'white'}
              bg={bg}
            >
              <Box flex={1} width={1}>
                <div>{key}</div>
                <div>{bg}</div>
              </Box>
            </ColorFlex>
          )
        })}
      </Flex>
    </Text>
  )
}

export const ThemeColors = () => {
  return (
    <Text small>
      <Flex flexWrap="wrap">
        {Object.keys(theme).map(key => {
          const color = theme[key]
          return (
            <ColorFlex
              key={key}
              p={0}
              color={getLuminance(color.hex) > 0.5 ? 'text' : 'white'}
            >
              <Box bg={color.hex} p="xs">
                <Box flex={1} width={1}>
                  <List>
                    <Li mb="xxs">
                      <Text bold>{color.name}</Text>
                    </Li>
                    <Li>{key}</Li>
                    <Li>RAL&nbsp;{color.ral}</Li>
                  </List>
                </Box>
                {color.hex}
              </Box>
              <Box bg={themeDark[key]} p="xs">
                {themeDark[key]}
              </Box>
            </ColorFlex>
          )
        })}
      </Flex>
    </Text>
  )
}

import { Playground, Props } from "docz";
import { Flex, Box, Text } from "@urbaninfrastructure/react-ui-kit";
import React from 'react';
export default {
  Playground,
  Props,
  Flex,
  Box,
  Text,
  React
};
// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-icons-mdx": () => import("./../../src/icons.mdx" /* webpackChunkName: "component---src-icons-mdx" */),
  "component---src-colors-mdx": () => import("./../../src/colors.mdx" /* webpackChunkName: "component---src-colors-mdx" */),
  "component---src-typography-mdx": () => import("./../../src/typography.mdx" /* webpackChunkName: "component---src-typography-mdx" */),
  "component---src-index-mdx": () => import("./../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-charts-bar-stack-horizontal-simple-mdx": () => import("./../../src/charts/BarStackHorizontalSimple.mdx" /* webpackChunkName: "component---src-charts-bar-stack-horizontal-simple-mdx" */),
  "component---src-charts-patterns-mdx": () => import("./../../src/charts/patterns.mdx" /* webpackChunkName: "component---src-charts-patterns-mdx" */),
  "component---src-react-ui-kit-alert-mdx": () => import("./../../src/react-ui-kit/Alert.mdx" /* webpackChunkName: "component---src-react-ui-kit-alert-mdx" */),
  "component---src-react-ui-kit-anchor-mdx": () => import("./../../src/react-ui-kit/Anchor.mdx" /* webpackChunkName: "component---src-react-ui-kit-anchor-mdx" */),
  "component---src-react-ui-kit-arrow-mdx": () => import("./../../src/react-ui-kit/Arrow.mdx" /* webpackChunkName: "component---src-react-ui-kit-arrow-mdx" */),
  "component---src-react-ui-kit-base-styles-mdx": () => import("./../../src/react-ui-kit/BaseStyles.mdx" /* webpackChunkName: "component---src-react-ui-kit-base-styles-mdx" */),
  "component---src-setup-mdx": () => import("./../../src/setup.mdx" /* webpackChunkName: "component---src-setup-mdx" */),
  "component---src-react-ui-kit-box-mdx": () => import("./../../src/react-ui-kit/Box.mdx" /* webpackChunkName: "component---src-react-ui-kit-box-mdx" */),
  "component---src-react-ui-kit-button-mdx": () => import("./../../src/react-ui-kit/Button.mdx" /* webpackChunkName: "component---src-react-ui-kit-button-mdx" */),
  "component---src-react-ui-kit-button-group-mdx": () => import("./../../src/react-ui-kit/ButtonGroup.mdx" /* webpackChunkName: "component---src-react-ui-kit-button-group-mdx" */),
  "component---src-react-ui-kit-checkbox-mdx": () => import("./../../src/react-ui-kit/Checkbox.mdx" /* webpackChunkName: "component---src-react-ui-kit-checkbox-mdx" */),
  "component---src-react-ui-kit-container-mdx": () => import("./../../src/react-ui-kit/Container.mdx" /* webpackChunkName: "component---src-react-ui-kit-container-mdx" */),
  "component---src-react-ui-kit-dialog-mdx": () => import("./../../src/react-ui-kit/Dialog.mdx" /* webpackChunkName: "component---src-react-ui-kit-dialog-mdx" */),
  "component---src-react-ui-kit-dot-mdx": () => import("./../../src/react-ui-kit/Dot.mdx" /* webpackChunkName: "component---src-react-ui-kit-dot-mdx" */),
  "component---src-react-ui-kit-css-grid-mdx": () => import("./../../src/react-ui-kit/CssGrid.mdx" /* webpackChunkName: "component---src-react-ui-kit-css-grid-mdx" */),
  "component---src-react-ui-kit-dropdown-mdx": () => import("./../../src/react-ui-kit/Dropdown.mdx" /* webpackChunkName: "component---src-react-ui-kit-dropdown-mdx" */),
  "component---src-react-ui-kit-flex-mdx": () => import("./../../src/react-ui-kit/Flex.mdx" /* webpackChunkName: "component---src-react-ui-kit-flex-mdx" */),
  "component---src-react-ui-kit-form-field-mdx": () => import("./../../src/react-ui-kit/FormField.mdx" /* webpackChunkName: "component---src-react-ui-kit-form-field-mdx" */),
  "component---src-react-ui-kit-grid-mdx": () => import("./../../src/react-ui-kit/Grid.mdx" /* webpackChunkName: "component---src-react-ui-kit-grid-mdx" */),
  "component---src-react-ui-kit-heading-mdx": () => import("./../../src/react-ui-kit/Heading.mdx" /* webpackChunkName: "component---src-react-ui-kit-heading-mdx" */),
  "component---src-react-ui-kit-hide-mdx": () => import("./../../src/react-ui-kit/Hide.mdx" /* webpackChunkName: "component---src-react-ui-kit-hide-mdx" */),
  "component---src-react-ui-kit-icon-button-mdx": () => import("./../../src/react-ui-kit/IconButton.mdx" /* webpackChunkName: "component---src-react-ui-kit-icon-button-mdx" */),
  "component---src-react-ui-kit-image-mdx": () => import("./../../src/react-ui-kit/Image.mdx" /* webpackChunkName: "component---src-react-ui-kit-image-mdx" */),
  "component---src-react-ui-kit-input-group-mdx": () => import("./../../src/react-ui-kit/InputGroup.mdx" /* webpackChunkName: "component---src-react-ui-kit-input-group-mdx" */),
  "component---src-react-ui-kit-info-window-mdx": () => import("./../../src/react-ui-kit/InfoWindow.mdx" /* webpackChunkName: "component---src-react-ui-kit-info-window-mdx" */),
  "component---src-react-ui-kit-input-mdx": () => import("./../../src/react-ui-kit/Input.mdx" /* webpackChunkName: "component---src-react-ui-kit-input-mdx" */),
  "component---src-react-ui-kit-label-mdx": () => import("./../../src/react-ui-kit/Label.mdx" /* webpackChunkName: "component---src-react-ui-kit-label-mdx" */),
  "component---src-react-ui-kit-list-mdx": () => import("./../../src/react-ui-kit/List.mdx" /* webpackChunkName: "component---src-react-ui-kit-list-mdx" */),
  "component---src-react-ui-kit-loadable-button-mdx": () => import("./../../src/react-ui-kit/LoadableButton.mdx" /* webpackChunkName: "component---src-react-ui-kit-loadable-button-mdx" */),
  "component---src-react-ui-kit-select-mdx": () => import("./../../src/react-ui-kit/Select.mdx" /* webpackChunkName: "component---src-react-ui-kit-select-mdx" */),
  "component---src-react-ui-kit-spinner-mdx": () => import("./../../src/react-ui-kit/Spinner.mdx" /* webpackChunkName: "component---src-react-ui-kit-spinner-mdx" */),
  "component---src-react-ui-kit-text-mdx": () => import("./../../src/react-ui-kit/Text.mdx" /* webpackChunkName: "component---src-react-ui-kit-text-mdx" */),
  "component---src-react-ui-kit-table-mdx": () => import("./../../src/react-ui-kit/Table.mdx" /* webpackChunkName: "component---src-react-ui-kit-table-mdx" */),
  "component---src-react-ui-kit-textarea-mdx": () => import("./../../src/react-ui-kit/Textarea.mdx" /* webpackChunkName: "component---src-react-ui-kit-textarea-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}


import { Props, Playground } from "docz";
import { Table, Caption, Tr, Th, Td } from "@urbaninfrastructure/react-ui-kit";
import React from 'react';
export default {
  Props,
  Playground,
  Table,
  Caption,
  Tr,
  Th,
  Td,
  React
};
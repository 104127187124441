import { Props, Playground } from "docz";
import { Heading, H1, H2, H3, Text } from "@urbaninfrastructure/react-ui-kit";
import React from 'react';
export default {
  Props,
  Playground,
  Heading,
  H1,
  H2,
  H3,
  Text,
  React
};
import React from 'react'
import useComponentSize from './useComponentSize'
import {
  Flex,
  Box,
  Text,
  getTheme,
  RelativeScale,
} from '@urbaninfrastructure/react-ui-kit'

const theme = getTheme()

export const FontSizeScale = () => {
  return (
    <div>
      {theme.fontSizes.map((fontSize, i) => (
        <Text key={fontSize}>
          <Flex alignItems="baseline">
            <Box width={70} textAlign="right" mr={4}>
              {i}:
            </Box>
            <Box fontSize={fontSize}>{fontSize}px</Box>
          </Flex>
        </Text>
      ))}
    </div>
  )
}

export const SpaceScale = () => {
  return (
    <div>
      {theme.space.map(s => (
        <Flex key={s} mb={4} alignItems="baseline">
          <Box textAlign="right" width={70} mr={4}>
            {s}px
          </Box>
          <Box size={s} bg="primary" />
        </Flex>
      ))}
    </div>
  )
}

const colorMap = {
  xxs: '#8B572A',
  xs: '#BD10E0',
  sm: '#4A90E2',
  md: '#7ED321',
  lg: '#D0021B',
  xl: '#F8E71C',
}

function RhythmBox({ rhythmKey, value }: { rhythmKey: string; value: string }) {
  const boxEl = React.useRef()
  const size = useComponentSize(boxEl)
  return (
    <Flex mb={4} alignItems="center">
      <Box textAlign="right" width={70} mr={4}>
        <Text small>
          {rhythmKey}
          <div>
            {size.width}x{size.height}
          </div>
        </Text>
      </Box>
      <Box ref={boxEl} size={value} bg={colorMap[rhythmKey]} />
    </Flex>
  )
}

export const Rhythms = () => {
  return (
    <div>
      {Object.keys(theme.rhythms).map(key => {
        const value = theme.rhythms[key]
        return <RhythmBox key={key} rhythmKey={key} value={value} />
      })}
    </div>
  )
}

export const TypoStyles = () => {
  return (
    <div>
      {Object.keys(theme.typoStyles).map((key: RelativeScale) => {
        return (
          <Text key={key} typoStyle={key} mb="sm">
            {key}: The quick brown fox jumps over the lazy dog
          </Text>
        )
      })}
    </div>
  )
}

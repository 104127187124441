import React from 'react'
import { renderToString } from 'react-dom/server'
import * as icons from '@urbaninfrastructure/react-icons'
import { theme, neutral } from '@urbaninfrastructure/design-tokens'
import {
  Box,
  CssGrid,
  Dialog,
  Text,
  Flex,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  ButtonAnchor,
} from '@urbaninfrastructure/react-ui-kit'

type Props = {
  large: boolean
}

const createDataURI = svg => `data:image/svg+xml;base64,${btoa(svg)}`

function ColorPickerBox({ name, hex, ...props }) {
  return <Box title={name} height={32} bg={hex} {...props} />
}

function IconDialog({ iconKey }: { iconKey: string }) {
  const [hex, setHex] = React.useState(neutral.dark01)
  const [size, setSize] = React.useState('32')
  const Icon = icons[iconKey]
  if (typeof Icon !== 'function') {
    return null
  }
  const icon = (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      fill={hex}
      color={hex}
    />
  )
  const stringified = renderToString(icon).replace('currentColor', hex)
  return (
    <Flex flexDirection="column" alignItems="center">
      <CssGrid
        mb="xs"
        width={1}
        gridRowGap="5px"
        gridColumnGap="5px"
        gridTemplateColumns={`repeat(8, 1fr)`}
      >
        {Object.keys(theme).map(key => {
          const value = theme[key]
          return (
            <ColorPickerBox
              key={key}
              {...value}
              onClick={() => setHex(value.hex)}
            />
          )
        })}
        {Object.keys(neutral).map(key => {
          const value = neutral[key]
          return (
            <ColorPickerBox
              key={key}
              name={key}
              hex={value}
              onClick={() => setHex(value)}
            />
          )
        })}
      </CssGrid>
      <CssGrid
        mb="xs"
        gridColumnGap="10px"
        gridTemplateColumns={`4rem .5fr .5fr`}
        alignItems="center"
      >
        <Label htmlFor="size">Size</Label>
        <InputGroup>
          <Input
            id="size"
            value={size}
            onChange={event => setSize(`${event.target.value}`)}
          />
          <InputGroupAddon>px</InputGroupAddon>
        </InputGroup>
        <input
          type="range"
          min="0"
          max="600"
          value={size}
          onChange={event => setSize(`${event.target.value}`)}
          step="5"
        />
      </CssGrid>
      <Flex
        mb="xxs"
        width={500}
        height={400}
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        {icon}
      </Flex>
      <ButtonAnchor
        variant="primary"
        href={createDataURI(stringified)}
        title={`Download ${iconKey} as SVG`}
        download={`${iconKey}.svg`}
        p={2}
        mb="xxs"
        display="block"
        textAlign="center"
      >
        Download
      </ButtonAnchor>
      <Text small mute>
        Protip! To use the icon in Google Slides, convert the SVG to PNG using{' '}
        <a href="https://cloudconvert.com/svg-to-png" target="blank">
          CloudConvert
        </a>
      </Text>
    </Flex>
  )
}

function AllIcons({ large }: Props) {
  return (
    <CssGrid
      gridRowGap={10}
      gridColumnGap={10}
      gridTemplateColumns={`repeat(auto-fit, minmax(150px, 1fr))`}
    >
      {Object.keys(icons).map(key => {
        const Icon = icons[key]
        if (typeof Icon !== 'function') {
          return null
        }
        if (large !== key.startsWith('Large')) {
          return null
        }
        return (
          <Dialog
            key={key}
            heading="Download icon as SVG"
            renderOpenButton={openPortal => {
              return (
                <Box
                  title={`Download ${key} as SVG`}
                  p={2}
                  display="block"
                  textAlign="center"
                  // @ts-ignore
                  onClick={openPortal}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon size={large ? '68px' : '32px'} color="primary" />
                  <Text fontSize={12} color="text">
                    {key}
                  </Text>
                </Box>
              )
            }}
          >
            {({ isOpen }) => {
              if (!isOpen) {
                return null
              }
              return <IconDialog iconKey={key} />
            }}
          </Dialog>
        )
      })}
    </CssGrid>
  )
}

export default AllIcons

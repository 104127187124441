import { Playground } from "docz";
import { FontSizeScale, TypoStyles, SpaceScale, Rhythms } from "../../../../../src/components/Typography";
import React from 'react';
export default {
  Playground,
  FontSizeScale,
  TypoStyles,
  SpaceScale,
  Rhythms,
  React
};
import { Props, Playground } from "docz";
import { Bike as BikeIcon } from "@urbaninfrastructure/react-icons";
import AllIcons from "../../../../../src/components/AllIcons";
import React from 'react';
export default {
  Props,
  Playground,
  BikeIcon,
  AllIcons,
  React
};